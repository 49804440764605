<template>
  <div>
    <div style="height: 100px"></div>
    <div class="d-flex">
      <div style="width:20%"></div>
      <div>
        <h1>Pelatihan dimulai tanggal 
          <p>{{ moment(start_evt).format("DD MMMM YYYY") }}</p>
        </h1>
        <v-btn text color="indigo" @click="logOut">
          Keluar
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
export default {
  computed: {
    ...mapState([
      "start_evt",
    ]),
  },
  name: "information",
  data() {
    return {
      moment: moment,
    };
  },
  methods: {
    logOut() {
      this.ErrMsg = "";
      localStorage.clear();
      this.$router.go("/login");
      this.dialogErr = false;
    },
  }
};
</script>

<style></style>
